<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Supply Date" vid="supply_date" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="supply_date"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('pump_install.supply_date') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              class="date-picker"
                              v-model="supply.supply_date"
                              placeholder="Select Date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Supply Note (En)" vid="supply_note" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="supply_note"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('pump_install.supply_note') }} <span class="text-danger">*</span>
                          </template>
                        <b-textarea
                            id="supply_note"
                            v-model="supply.supply_note"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Supply Note (Bn)" vid="supply_note_bn">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('pump_install.supply_note_bn')"
                        label-for="supply_note_bn"
                        slot-scope="{ valid, errors }"
                        >
                        <b-textarea
                            id="supply_note_bn"
                            v-model="supply.supply_note_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { complainSupplyEquipmentStore } from '../../api/routes'
import IrrigationMixin from '../../../../../mixins/irrigation-dropdown'
import flatpickr from 'flatpickr'

export default {
  name: 'FormLayout',
  mixins: [IrrigationMixin],
  props: ['id', 'complain_requisition_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      supply: {
        complain_id: this.$props.id,
        requisition_id: this.$props.complain_requisition_id,
        supply_date: '',
        status: 10
      }
    }
  },
  computed: {
    getPumpType: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
    },
    getHorsePower: function () {
      return this.$store.state.IrriConfig.commonObj.horsePower.filter(item => item.status === 0)
    },
    categoryList: function () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0)
    },
    unitList: function () {
     return this.$store.state.IrriConfig.commonObj.unitList.filter(item => item.status === 0)
    }
  },
  methods: {
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, complainSupplyEquipmentStore, this.supply)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
        this.$router.push('maintenance-task')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
