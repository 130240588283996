<template>
  <b-container fluid>
      <b-row v-show="dataLoading">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                    <h5 class='complain-title'>{{ $t('pump_install.requisition_details') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.organization') }} : </p>
                      </b-col>
                      <b-col lg="8" sm="12">
                         <p class="text-dark">{{ organizationName }}</p>
                      </b-col>
                      <b-col lg="2" sm="12">
                         <b-button class="btn btn-outline-success" variant="iq-bg-success" size="sm" title="Download"><i class="ri-download-cloud-fill"></i> </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('pump_install.requisition_date') }} : </p>
                      </b-col>
                      <b-col lg="10" sm="12">
                         <p class="text-dark">{{ requistion.requisition_date | dateFormat }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('pump_install.requisition_id') }} : </p>
                      </b-col>
                      <b-col lg="10" sm="12">
                         <p class="text-dark">{{ requistion.requisition_id }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table bordered :fields="columns" :items="items"></b-table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="2" sm="12">
                    <p class="text-dark">{{ $t('pump_install.remarks') }} : </p>
                  </b-col>
                  <b-col lg="10" sm="12">
                      <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? requistion.remarks_bn : requistion.remarks }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="8" sm="12"></b-col>
                  <b-col lg="4" sm="12">
                    <div class="col text-right">
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { complainRequisitionDetails } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id', 'complain_requisition_id'],
  created () {
    if (this.id) {
      this.getcomplainRequisitionDetails()
    }
  },
  data () {
    return {
      modalType: -111,
      forward_type: '',
      modalTitle: '',
      complain_id: '',
      isEdit: true,
      dataLoading: false,
      organizationName: '',
      requistion: '',
      requistion_details: '',
      items: []
    }
  },
  computed: {
    columns () {
      const labels = [
        this.$t('pump_install.category'),
        this.$t('pump_install.item_name'),
        this.$t('pump_install.measurement_unit'),
        this.$t('pump_install.stock_quantity'),
        this.$t('pump_install.stock_out_quantity'),
        this.$t('pump_install.current_stock')
      ]

      const keys = [
        { key: 'category' },
        { key: 'item_name' },
        { key: 'measurement_unit' },
        { key: 'stock_quantity' },
        { key: 'stock_out_quantity' },
        { key: 'current_stock' }
      ]

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getcomplainRequisitionDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, complainRequisitionDetails + '/' + this.$props.id + '/' + this.$props.complain_requisition_id)
      if (result.success) {
        this.requistion = result.data.requistion
        this.items = []
        this.items = this.formatList(result.data.requistion_details)
        this.getOrganization(this.requistion.org_id)
        this.dataLoading = true
      }
    },
    getOrganization (orgId) {
      const orgList = this.$store.state.orgList
      const tmpOrg = orgList.find(org => org.value === orgId)
      this.organizationName = this.$i18n.locale === 'bn' ? tmpOrg.text_bn : tmpOrg.text_en
    },
    formatList (data) {
      if (this.$i18n.locale === 'bn') {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name_bn }
          const tmpSubCategory = { sub_category: item.sub_category_name_bn }
          const tmpItemName = { item_name: item.item_name_bn }
          const tmpUnit = { measurement_unit: item.unit_name_bn }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const tmpCurrentStock = { current_stock: parseFloat(item.stock_quantity - item.stock_out_quantity) }
          return Object.assign({}, isActive, tmpCategory, tmpSubCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      } else {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name }
          const tmpSubCategory = { sub_category: item.sub_category_name }
          const tmpItemName = { item_name: item.item_name }
          const tmpUnit = { measurement_unit: item.unit_name }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const tmpCurrentStock = { current_stock: parseFloat(item.stock_quantity - item.stock_out_quantity) }
          return Object.assign({}, isActive, tmpCategory, tmpSubCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
