<template>
  <b-container fluid>
      <b-row v-show="dataLoading">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.supply_notel') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
                    <tr>
                      <th>{{ $t('pump_install.supply_date') }}</th>
                      <td>{{ supply_note.supply_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('pump_install.supply_notel') }}</th>
                      <td>{{ ($i18n.locale === 'bn') ? supply_note.supply_note_bn : supply_note.supply_note }}</td>
                    </tr>
                  </table>
                </b-row>
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-4">
                    <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.print') }}
                    </b-button>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-4">
                  <h5 class='complain-title'>{{ $t('pump_install.supply_note') }}</h5>
                </b-col>
              </b-row>
              <b-row>
                <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
                  <tr>
                    <th>{{ $t('pump_install.supply_date') }}</th>
                    <td>{{ supply_note.supply_date | dateFormat }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('pump_install.supply_note') }}</th>
                    <td>{{ ($i18n.locale === 'bn') ? supply_note.supply_note_bn : supply_note.supply_note }}</td>
                  </tr>
                </table>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { complainSupplyEquipmentDetails } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
export default {
  name: 'FormLayout',
  props: ['id', 'orgId', 'complain_requisition_id'],
  created () {
    if (this.id) {
      this.getSupplyEquipment()
    }
  },
  data () {
    return {
        dataLoading: false,
        supply_note: ''
    }
  },
  methods: {
    async getSupplyEquipment () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, complainSupplyEquipmentDetails + '/' + this.$props.complain_requisition_id)
      if (result.success) {
        this.dataLoading = true
        this.supply_note = result.data
      }
    },
    pdfExport () {
      const rowData = []
       const currentDate = this.$i18n.locale === 'bn' ? this.$t(this.supply_note.supply_date) : this.supply_note.supply_date
       const date = [
          { text: this.$t('pump_install.supply_date') + ' : ' + currentDate, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      const supplyNote = this.$i18n.locale === 'bn' ? this.$t(this.supply_note.supply_note) : this.supply_note.supply_note
      const supply = [
          { text: this.$t('pump_install.supply_note') + ' : ' + supplyNote, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [date, supply]
      }
       rowData.unshift(date, supply)
      const reportTitle = this.$i18n.locale === 'en' ? 'Supply Note' : 'সরবরাহ নোট'
       const columnWids = ['20%', '20%', '20%', '20%', '20%']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.$props.orgId, reportTitle, rowData, columnWids, extraData)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
